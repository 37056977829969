<template>
	<section class="relative mt-12 z-10">
		<!-- white half bg -->
		<div class="mx-auto max-w-6xl">
			<h2 class="blog_title text-2xl sm:px-1 sm:text-3xl font-bold text-black mb-8">{{ $t("latest_posts.bits") }}</h2>
			<!--  Skeleton Loader -->
			<div v-if="!blogPosts.length" class="flex justify-around w-full pb-14 md:pb-0 px-5 sm:px-10 mt-10">
				<v-skeleton-loader v-for="(skeleton, j) in 3" :key="j" width="300" type="card,paragraph"></v-skeleton-loader>
			</div>
			<!--  Latests Post carousel -->
			<div v-if="!!blogPosts.length" class="mb-10">
				<VueSlickCarousel v-bind="settings" class="related_blog_carousel lg:m-[10px]">
					<div v-for="(post, index) in blogPosts" :key="index" class="related_blog_carousel_item">
						<nuxt-link class="overflow-hidden w-full h-full hover:opacity-90 transition-all ease-in-out" :to="localePath('/blog/' + post.slug)">
							<img :src="postImage(post)" alt="" class="aspect-square object-cover" />
						</nuxt-link>
						<div class="bg-primary-500 text-white px-6 py-5">
							<div class="text-base mb-2">{{ $helpers.functions.formattedDate(post.date, $i18n.locale) }}</div>
							<div class="text-lg font-medium mb-2 h-12 overflow-hidden">{{ post.title }}</div>
							<nuxt-link class="!text-black text-sm font-bold" :to="localePath('/blog/' + post.slug)">{{ $t("latest_posts.continue") }}</nuxt-link>
						</div>
					</div>
				</VueSlickCarousel>
			</div>

			<div class="w-full mx-auto">
				<div class="mx-auto max-w-fit">
					<nuxt-link :to="localePath('/blog')" class="my-2 !text-white hover:scale-105 bg-primary-500 py-2 px-8 mx-auto transition-all ease-in-out block text-center"> Our blog </nuxt-link>
				</div>
			</div>
		</div>
	</section>
</template>
<style>
.slick-prev:before,
.slick-next:before {
	color: #6f6f70;
	font-size: 30px;
}
.related_blog_carousel .slick-track {
	display: flex;
	gap: 20px;
	justify-content: space-between;
}

@media only screen and (min-width: 767px) {
	.related_blog_carousel {
		margin: 0px -10px;
	}
}
@media only screen and (max-width: 639px) {
	.slick-prev {
		left: 15px;
		z-index: 9999;
	}
	.slick-next {
		right: 15px;
		z-index: 9999;
	}
	.slick-prev,
	.slick-next {
		transform: translate(0%, 1150%);
	}
}
@media only screen and (max-width: 380px) {
	.slick-prev,
	.slick-next {
		height: 18px;
	}
}
</style>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
	name: "LatestsPosts",
	props: {
		blogPosts: {
			type: Array,
			default: false,
			required: false
		}
	},
	methods: {
		postImage(postObj) {
			return postObj.featuredImage.node.mediaItemUrl ? postObj.featuredImage.node.mediaItemUrl : null;
		}
	},
	data() {
		return {
			settings: {
				dots: false,
				focusOnSelect: true,
				infinite: true,
				arrows: true,
				speed: 500,
				slidesToShow: 3,
				slidesToScroll: 3,
				touchThreshold: 5,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							centerMode: false,
							dots: false
						}
					},
					{
						breakpoint: 500,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							centerMode: true,
							dots: false
						}
					},
					{
						breakpoint: 380,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							centerMode: true,
							dots: false
						}
					}
				]
			}
		};
	},
	components: {
		VueSlickCarousel
	},
	computed: {
		columns() {
			if (this.$vuetify.breakpoint.xl) {
				return 3;
			}

			if (this.$vuetify.breakpoint.lg) {
				return 2;
			}

			if (this.$vuetify.breakpoint.md) {
				return 2;
			}

			return 1;
		}
	}
};
</script>
