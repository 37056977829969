<template>
	<section class="bg-[#F3F3F4] py-12 lg:py-6 px-5 lg:px-12">
		<div class="max-w-6xl mx-auto">
			<h2 v-if="showTitle" class="text-2xl sm:text-3xl font-bold text-black mb-6">{{ $t("faq.questions") }}</h2>
			<v-expansion-panels flat>
				<v-expansion-panel v-for="(faqPost, i) in faqPosts" :key="i" style="background: #f3f3f4">
					<v-expansion-panel-header class="faq-title text-primary-500" style="padding-left: 0; padding-right: 0">{{ faqPost.title }}</v-expansion-panel-header>
					<v-expansion-panel-content class="pt-4" v-html="faqPost.excerpt"> </v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<p v-if="faqLink" class="text-sm text-gray-500 px-6 pt-4">
				{{ $t("faq.more_questions") }} <nuxt-link :to="localePath('/faq')" class="text-primary-500 hover:underline">{{ $t("faq.questions_short") }}</nuxt-link>
			</p>
		</div>
	</section>
</template>

<script>
export default {
	name: "FAQ",
	props: {
		faqPosts: {
			type: Array,
			default: false,
			required: false
		},
		showTitle: {
			type: Boolean,
			default: false,
			required: false
		},
		faqLink: {
			type: Boolean,
			default: false,
			required: false
		}
	}
};
</script>

<style>
.faq-title {
	border-bottom-width: 2px;
	border-color: black;
	border-style: solid;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.5rem;
}
</style>
