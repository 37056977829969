<template>
	<div class="">
		<div class="relative">
			<!-- <div v-if="banner" :style="{ backgroundImage: `url(${banner})` }" class="absolute  bg-blend-overlay w-full md:h-full hidden md:block bg-cover bg-bottom left-0 top-0 z-0"></div> -->
			<div class="md:absolute lg:top-[40%] left-0 right-0 mx-auto max-w-6xl z-10">
				<!-- Search Box -->
				<div class="w-full md:w-auto z-10 md:mx-6">
					<div class="w-full mx-auto md:max-w-7xl">
						<div class="w-full lg:w-auto hidden lg:inline-block bg-white p-4 text-gray-500 border-b-[1px] border-gray-500 font-bold text-lg">Make Your Reservation</div>
					</div>
					<div class="xl:block md:max-w-7xl bg-white pb-6 xl:py-[30px] px-6 w-full pt-6 mx-auto">
						<SearchForm />
					</div>
				</div>
			</div>
			<v-carousel :hide-delimiters="true" :show-arrows-on-hover="true" :height="carouselHeight" class="lg:h-full bottom-0 d-flex flex-column">
				<v-carousel-item v-for="(item, key) in banners" :key="key" :src="item.banners.sourceUrl" cover>
					<div>
						<div class="absolute bottom-0 left-0 right-0 p-4 pt-0 md:pt-10 md:max-w-full mx-auto">
							<h1 class="relative z-20 font-bold text-3xl md:text-4xl md:w-8/12 mx-auto sm:w-auto text-black mb-1 text-center" v-html="item?.title"></h1>
							<p class="text-black text-[15px] relative z-20 text-center">{{ item?.subtitle }}</p>
						</div>
					</div>
				</v-carousel-item>
			</v-carousel>
		</div>
	</div>
</template>

<script>
import SearchForm from "@/components/SearchForm";
export default {
	name: "Hero",
	props: {
		title: {
			type: String,
			required: false
		},
		subtitle: {
			type: String,
			required: false
		},
		banner: {
			type: String,
			required: false
		},
		banners: {
			type: Array,
			required: false
		}
	},
	data() {
		return {
			attrs: {
				class: "mx-auto",
				boilerplate: true,
				secondTitle: "_hero.title",
				secondSubtitle: "_hero.subtitle"
			}
		};
	},
	components: {
		SearchForm
	},
	computed: {
		carouselHeight() {
			const width = this.$vuetify.breakpoint.width;
			if (width > 767) {
				return 720;
			}
			return 390;
		}
	}
};
</script>
