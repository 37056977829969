<template>
	<div class="text-base text-black">
		<!-- Features -->
		<section class="bg-white pt-16 pb-40">
			<div class="max-w-base mx-auto px-5 sm:px-10 grid gap-y-10 sm:gap-y-5 sm:grid-cols-2 xl:grid-cols-4 gap-5">
				<!--  Skeleton Loader -->
				<div v-show="!features.length" v-for="(skeleton, k) in 4" :key="k + 10" class="flex flex-col items-center justify-center text-center sm:justify-start sm:text-left mb-12">
					<v-skeleton-loader width="200" type="list-item-avatar, paragraph"></v-skeleton-loader>
				</div>
				<div v-for="(feature, index) in features" :key="index" class="">
					<div class="flex align-left justify-center md:justify-left">
						<img :src="feature.icon.sourceUrl" class="flex-shrink-0 h-8 text-primary-500" alt="" loading="lazy" />
					</div>
					<div class="mt-3 w-full">
						<h4 class="text-center md:text-left text-xl font-bold text-primary-500">
							{{ feature.title }}
						</h4>
						<p class="text-center md:text-left text-[15px] text-gray-500 md:mt-3 md:h-24">
							{{ feature.description }}
						</p>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: "Features",
	props: {
		features: {
			type: Array,
			required: false
		}
	}
};
</script>
