<template>
	<section class="-mt-[125px] sm:px-10 lg:px-11 max-w-6xl mx-auto">
		<div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
			<div class="w-full bg-primary-500 flex flex-col justify-start text-white" v-for="(post, index) in stickyPosts" :key="index">
				<h3 class="text-3xl font-bold px-10 md:px-16 pt-12" v-html="post?.title"></h3>
				<div class="flex py-6">
					<img src="~/assets/img/icon_star_line.svg" />
				</div>
				<p class="text-lg px-10 md:px-16" v-html="post?.content"></p>
				<div class="p-12 md:px-16 md:pb-12 md:pt-0 w-full h-full flex justify-center" v-if="!!postImage(post)">
					<img :src="postImage(post)" class="w-1/2 object-cover" />
				</div>
				<div class="w-full flex justify-center pb-12">
					<nuxt-link :to="localePath(postUrl(post))" class="text-center mb-2 text-white hover:scale-105 w-1/2 bg-black px-1 py-3">{{ $t("_navbarmain.view_more") }}</nuxt-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "AwardWinners",
	props: ["stickyPosts"],
	methods: {
		postImage(post) {
			return post?.featuredImage?.node?.mediaItemUrl;
		},
		postUrl(post) {
			// ACF custom field -> https://car.siriusrentacar.gr/wp-admin/post.php?post=73038&action=edit
			let postUrl = "/blog/" + post?.slug; //default case
			if (post?.postsmeta?.postsMeta?.customUrlEn || post?.postsmeta?.postsMeta?.customUrlEl) {
				postUrl = post?.postsmeta?.postsMeta?.customUrlEn;
				if (this.$i18n.locale == "el") {
					postUrl = post?.postsmeta?.postsMeta?.customUrlEl;
				}
			}
			return postUrl;
		}
	}
};
</script>
