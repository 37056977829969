<template>
	<div>
		<Hero :title="heroContent.title" :subtitle="heroContent.subtitle" :banners="homeHeroBanners" />
		<Features :features="homeFeatures" />
		<AwardWinners v-if="stickyPost?.length" :stickyPosts="stickyPost" />
		<div class="relative">
			<div class="mx-auto max-w-6xl px-2 sm:px-10 lg:px-11">
				<LatestPosts :blogPosts="latestPosts" />
			</div>
			<!-- <div class="bg-full-white bg-white absolute top-2/3 bottom-0 inset-x-0 z-0 w-full"></div> -->
		</div>
		<FAQ :faqPosts="faqPosts" showTitle faqLink />
	</div>
</template>

<script>
import homeQuery from "@/apollo/queries/home";
import Hero from "@/components/Hero";
import AwardWinners from "@/components/sections/AwardWinners";
import CallToAction from "@/components/sections/CallToAction";
import FAQ from "@/components/sections/FAQ";
import Features from "@/components/sections/Features";
import LatestPosts from "@/components/sections/LatestPosts";
export default {
	name: "index",
	layout: "home",
	components: {
		Hero,
		Features,
		AwardWinners,
		LatestPosts,
		FAQ,
		CallToAction
	},
	data() {
		return {
			homeTexts: {},
			homeFeatures: [],
			heroContent: {},
			latestPosts: [],
			faqPosts: [],
			stickyPost: {}
		};
	},
	computed: {
		homeHeroTitle() {
			return this.homeTexts?.homeHeroTitle;
		},
		homeHeroSubtitle() {
			return this.homeTexts?.homeHeroSubtitle;
		},
		homeHeroBanners() {
			return this.homeTexts?.htechSettings?.acfOptionsPage?.sliderBanners ?? [];
		}
	},
	methods: {
		async getHomeTexts() {
			//fetch the content through graphql from WP
			this.homeTexts = await this.$apollo.queries.htechSettings.refetch().then(res => res.data);
			
			//FAQ posts
			this.faqPosts = this.homeTexts.faqs.nodes.map(faq => {
				return this.$helpers.functions.$t(faq, this.$i18n.locale);
			});

			//Latest Posts
			this.latestPosts = this.homeTexts.latestPosts.nodes.map(item => this.$helpers.functions.$t(item, this.$i18n.locale));
			this.homeTexts.stickyPost.nodes
			
			//Stickpost content
			this.stickyPost = this.homeTexts.stickyPost.nodes.map(function (post) {
				return this.$helpers.functions.$t(post, this.$i18n.locale);
			}, this)
			
			//Hero Banner Texts
			if (this.$i18n.locale == "el") {
				//Features Why Us
				this.homeFeatures = this.homeTexts.htechSettings.acfOptionsPage.lang.homeFeaturesEl;

				this.heroContent = {
					title: this.homeTexts?.htechSettings?.acfOptionsPage?.lang.heroTitleEl,
					subtitle: this.homeTexts?.htechSettings?.acfOptionsPage?.lang.homeHeroSubtitleEl,
					banner: this.homeTexts?.htechSettings?.acfOptionsPage?.homeHeroBanner?.sourceUrl
				};
			} else {
				//Features Why Us
				this.homeFeatures = this.homeTexts.htechSettings.acfOptionsPage.lang.homeFeaturesEn;

				this.heroContent = {
					title: this.homeTexts?.htechSettings?.acfOptionsPage?.lang.heroTitleEn,
					subtitle: this.homeTexts?.htechSettings?.acfOptionsPage?.lang.homeHeroSubtitleEn
				};
			}
		}
	},
	apollo: {
		htechSettings: {
			prefetch: false,
			query: homeQuery
		}
	},
	fetchOnServer: false,
	async fetch() {
		await this.getHomeTexts();
	}
};
</script>
