<template>
    <section class="py-8 px-5 sm:px-10 bg-warning" data-block_626fc176be1bf="">
        <p style="text-align: center;">Need <strong color="text-blue-200">HELP</strong> renting a car online? (+30) 210 9919979</p>
    </section>
</template>

<script>
export default {
    name: 'CallToAction',
}
</script>

<style>

</style>